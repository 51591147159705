module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":768,"linkImagesToOriginal":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/phoenix.png","name":"beaucarigans realm","short_name":"beaucarigan.de","start_url":"/","background_color":"#00bbee","theme_color":"#0be","display":"standalone","legacy":false,"cache_busting_mode":"query","include_favicon":true,"theme_color_in_head":true,"cacheDigest":"86ba4fe07deb5901c62629778df6a823"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"2","matomoUrl":"https://stats.beaucarigan.de","siteUrl":"https://beaucarigan.de"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
