// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kickern-tsx": () => import("./../src/pages/kickern.tsx" /* webpackChunkName: "component---src-pages-kickern-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-pages-wpplugin-tsx": () => import("./../src/pages/wpplugin.tsx" /* webpackChunkName: "component---src-pages-wpplugin-tsx" */),
  "component---src-templates-blog-post-index-tsx": () => import("./../src/templates/BlogPost/index.tsx" /* webpackChunkName: "component---src-templates-blog-post-index-tsx" */),
  "component---src-templates-kickern-post-index-tsx": () => import("./../src/templates/KickernPost/index.tsx" /* webpackChunkName: "component---src-templates-kickern-post-index-tsx" */),
  "component---src-templates-wp-plugin-index-tsx": () => import("./../src/templates/WPPlugin/index.tsx" /* webpackChunkName: "component---src-templates-wp-plugin-index-tsx" */)
}

